<template>
  <v-container fluid>
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <ButtonAdicionar
            :temPermissao="
              $store.getters['usuario/temPermissao']('inserir.planos')
            "
            to="/planos/adicionar"
          />
        </v-col>
      </v-row>
    </v-card-actions>
    <v-row class="mt-4">
      <v-col>
        <v-data-table
          disable-filtering
          disable-sort
          :server-items-length="totalRegistros"
          :headers="cabecalho"
          :items="planos"
          :options.sync="paginacao"
          :footer-props="{
            'items-per-page-options': [10, 20, 50],
          }"
          @update:options="carregarPlanos"
        >
          <template v-slot:[`item.default`]="{ item }">
            <span :class="{ 'status-ativo': item.default == 'Ativo' }">{{
              item.default
            }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div>
              <table-btn-editar
                :temPermissao="
                  $store.getters['usuario/temPermissao']('editar.planos')
                "
                :href="`/planos/${item.id}/editar`"
              />
              <table-btn-mostrar
                :temPermissao="
                  $store.getters['usuario/temPermissao']('mostrar.planos') &&
                  !$store.getters['usuario/temPermissao']('editar.planos')
                "
                :href="`/planos/${item.id}/editar`"
              />
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import planos from "@/api/planos";

export default {
  components: {
    ButtonAdicionar: () => import("@/components/button/ButtonAdicionar"),
  },

  data() {
    return {
      totalRegistros: 0,
      cabecalho: [
        { text: "#", value: "id" },
        { text: "Descrição", value: "descricao" },
        { text: "Nro. Tickets", value: "tickets" },
        { text: "Valor Mensal", value: "valor_mensal_formatado" },
        { text: "Valor Anual", value: "valor_anual_formatado" },
        { text: "Areas", value: "numero_areas" },
        { text: "Status", value: "status" },
        { text: "Plano Padrão", value: "default" },
        { text: "Ações", value: "actions", align: "right" },
      ],
      planos: [],
      paginacao: {},
      tab: null,
    };
  },

  methods: {
    async carregarPlanos() {
      try {
        const options = {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 3,
        };

        this.$store.commit(`layout/SET_LOADING`, true);

        const resposta = await planos.listar({
          pagina: this.paginacao.page,
          por_pagina: this.paginacao.itemsPerPage,
        });

        this.planos = resposta.data.data;

        this.planos.forEach((item) => {
          item.status = item.ativo == 1 ? "Ativo" : "Inativo";
          item.default = item.default ? "Ativo" : "";

          item.valor_mensal_formatado = new Intl.NumberFormat(
            "pt-BR",
            options
          ).format(item.valor_mensal);
          item.valor_anual_formatado = new Intl.NumberFormat(
            "pt-BR",
            options
          ).format(item.valor_anual);
        });

        this.totalRegistros = resposta.data.meta.total;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
  },
};
</script>
<style scoped>
.tables {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status-ativo {
  color: green;
  font-weight: bold;
}
</style>
